@import url("https://fonts.googleapis.com/css2?family=Public+Sans:wght@400;500;600;700;800");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    html {
        @apply font-public;
    }
}

@layer components {
    .button {
        @apply cursor-pointer select-none rounded-lg bg-gray-900 px-6 py-3 text-center align-middle font-public text-xs font-bold uppercase text-white shadow-md shadow-gray-900/10 transition-all focus-visible:outline-0 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none;
    }
    .button:hover {
        @apply shadow-lg shadow-gray-900/20;
    }

    .button--primary {
        @apply bg-gradient-to-tr from-purple-600 to-purple-400 shadow-md shadow-purple-500/20;
    }
    .button--primary:hover {
        @apply shadow-lg shadow-purple-500/40;
    }

    .button--red {
        @apply bg-gradient-to-tr from-red-600 to-red-400 shadow-md shadow-red-500/20;
    }
    .button--red:hover {
        @apply shadow-lg shadow-red-500/40;
    }

    .form-input {
        @apply w-full rounded-md border border-gray-900 border-opacity-25 p-2.5 font-medium text-gray-500 placeholder:text-sm placeholder:font-medium focus-visible:outline-primary;
    }

    .refresh-button {
        @apply flex cursor-pointer justify-center rounded-md border border-primary border-opacity-25 bg-primary p-2.5 text-white;
    }

    .refresh-button:hover {
        @apply border-primary-dark bg-primary-dark text-gray-200 shadow-sm shadow-purple-500/40;
    }

    .link {
        @apply flex cursor-default items-center gap-6 rounded-md p-2.5 font-medium duration-300 md:cursor-pointer;
    }

    .active {
        @apply bg-purple-100 text-purple-600;
    }
}

body {
    margin: 0;
    font-family:
        "Public Sans",
        -apple-system,
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Public Sans",
        monospace;
}

::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
    background: #8b26b2;
    border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
    background: #cd8be6;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/*preLoad*/
div#preloader {
    align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
}

div#preloader {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

div#preloader {
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
}

div#preloader {
    background: #fff;
    position: fixed;
    z-index: 999999;
    width: 100%;
    height: 100vh;
}

.circular-progress {
    border: 8px solid rgba(139, 38, 178, 0.2);
    border-radius: 50%;
    border-top-color: #8b26b2;
    animation: spin 2s linear infinite;
    height: 72px;
    width: 72px;
}

.logo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 35px;
    height: 35px;
}

@keyframes spin {
    100% {
        transform: rotate(360deg);
    }
}

.preloader-content {
    transition: opacity 0.5s ease-out;
}

/* TOTAL TRX */
.total-order {
    width: 100%;
}

.total-order ul {
    margin: 0;
    padding: 0;
}

.total-order ul li {
    display: flex;
    border-width: 1px;
    border-top-width: 0;
    --tw-border-opacity: 1;
    border-color: rgb(248 248 248 / var(--tw-border-opacity));
}

.total-order ul li h4 {
    display: flex;
    align-items: center;
    width: 50%;
    border-right-width: 1px;
    --tw-border-opacity: 1;
    border-color: rgb(248 248 248 / var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: rgb(250 251 254 / var(--tw-bg-opacity));
    padding: 10px;
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 500;
    --tw-text-opacity: 1;
    color: rgb(99 115 129 / var(--tw-text-opacity));
}

.total-order ul li h5 {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 50%;
    text-align: right;
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 700;
    --tw-text-opacity: 1;
    color: rgb(33 43 54 / var(--tw-text-opacity));
}

.total-order ul li:first-child {
    border-top-width: 1px;
    --tw-border-opacity: 1;
    border-color: rgb(248 248 248 / var(--tw-border-opacity));
}

.total-order ul li.total h5 {
    font-weight: 700;
    --tw-text-opacity: 1;
    color: rgb(33 43 54 / var(--tw-text-opacity));
}

.total-order ul li.total h4 {
    font-weight: 700;
    --tw-text-opacity: 1;
    color: rgb(94 88 115 / var(--tw-text-opacity));
}

.swal2-container {
    z-index: 10000;
}
/* END TOTAL TRX */
